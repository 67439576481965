import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useLocation, Outlet } from "react-router-dom";
import MyContext from "../../Provider/MyContext";
import NoPermissionPage from "../../components/NoPermission";
import { FeatureKeys } from "../../enums";

const Settings = () => {
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const location = useLocation();

  // Define tabs
  const Tabs = [
    {
      key: "offers",
      route: "discounts",
      name: Context.langData.discounts,
      id: 0,
    },
    { key: "shifts", route: "shifts", name: Context.langData.shifts, id: 1 },
    {
      key: "printers",
      route: "printers",
      name: Context.langData.printers,
      id: 2,
    },
    {
      key: "kitchen",
      route: "kitchens",
      name: Context.langData.kitchen_creation,
      id: 3,
    },
    {
      key: "delivery_options",
      route: "delivery-options",
      name: Context.langData.delivery_options,
      id: 4,
    },
    {
      key: "End_of_Day_Setting",
      route: "end-of-day-settings",
      name: Context.langData.Reports,
      id: 5,
    },
    {
      key: "taxes",
      route: "taxes",
      name: Context.langData.taxes,
      id: 0,
    },
    {
      key: "taxes",
      route: "tax-profiles",
      name: Context.langData.taxProfiles,
      id: 1,
    },
    {
      route: "terminals",
      name: Context.langData.terminals,
      id: 2,
    },
  ];

  // Add feature flag-based tab dynamically
  const featureFlagClient = useLDClient();
  if (featureFlagClient) {
    const flagValue = featureFlagClient.variation(
      FeatureKeys.KitchenTicket,
      false
    );
    if (flagValue) {
      Tabs.push({
        key: "Kitchen_Guest_Receipt_Setting",
        route: "kitchen-guest-receipt-settings",
        name: Context.langData.kitchen_tickets,
        id: 6,
      });
    }
  }

  // Permission mapping
  const isItemHasPermission = useMemo(
    () =>
      Tabs.map((tab) => {
        if (tab.key) {
          const permission = userPermissions.find(
            (perm) => perm.key === tab.key
          );
          return {
            ...tab,
            isPermission: permission?.status || false,
          };
        }
        return {
          ...tab,
          isPermission: true,
        };
      }),
    [userPermissions, Tabs]
  );

  // Determine the selected tab based on the current location
  const selectedIndex = Tabs.findIndex((tab) =>
    location.pathname.includes(tab.route)
  );

  return (
    <Grid
      data-qaid='menu_options_addbutton'
      style={{ height: "80%", width: "100%" }}>
      {selectedIndex !== -1 &&
      isItemHasPermission[selectedIndex]?.isPermission ? (
        <Outlet />
      ) : (
        <NoPermissionPage />
      )}
    </Grid>
  );
};

export default Settings;

/* eslint-disable no-restricted-globals */
import { useState, useEffect, useContext } from "react";
import _ from "lodash";
import MyContext from "../Provider/MyContext";

const defaultModifierGroup = {
  addon_cat_name: "",
  is_mandatory: false,
  set_max_selection: true,
  minimum_select: 0,
  maximum_select: 1,
  set_quantity_limit: false,
  minimum_quantity: 1,
  maximum_quantity: 1,
  addon_items: [
    {
      addon_item_name: "",
      position: 0,
      base_price: null,
    },
  ],
};

const useModifierGroup = (initialData = null) => {
  const { langData } = useContext(MyContext);
  const [modifierGroup, setModifierGroup] = useState(
    initialData || defaultModifierGroup
  );
  const [initialModifierGroup, setInitialModifierGroup] = useState(null);
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(!!initialData);

  useEffect(() => {
    if (initialData) {
      const sortAddonItems = _.orderBy(
        initialData.addon_items,
        ["position"],
        ["asc"]
      );
      setModifierGroup({ ...initialData, addon_items: sortAddonItems });
      setInitialModifierGroup({ ...initialData, addon_items: sortAddonItems });
      setIsEditing(true);
    }
  }, [initialData]);

  useEffect(() => {
    if (
      modifierGroup.set_quantity_limit &&
      modifierGroup.set_max_selection &&
      modifierGroup.maximum_quantity > modifierGroup.maximum_select
    ) {
      updateModifierGroup("maximum_quantity", modifierGroup.maximum_select);
    }
  }, [modifierGroup.maximum_select]);

  useEffect(() => {
    if (modifierGroup.is_mandatory && modifierGroup.minimum_select === 0) {
      updateModifierGroup("minimum_select", 1);
    } else if (
      !modifierGroup.is_mandatory &&
      modifierGroup.minimum_select === 1
    ) {
      updateModifierGroup("minimum_select", 0);
    }
  }, [modifierGroup.is_mandatory]);
  const updateModifierGroup = (field, value) => {
    setModifierGroup((prev) => ({ ...prev, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  const updateAddonItem = (index, field, value) => {
    setModifierGroup((prev) => ({
      ...prev,
      addon_items: prev.addon_items.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${field}_${index}`]: null,
    }));
  };

  const addAddonItem = () => {
    setModifierGroup((prev) => ({
      ...prev,
      addon_items: [
        ...prev.addon_items,
        {
          addon_item_name: "",
          position: prev.addon_items.length,
          base_price: null,
        },
      ],
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      addon_items: null,
    }));
  };

  const removeAddonItem = (index) => {
    const removeItem = modifierGroup.addon_items.filter((_, i) => i !== index);
    const reassignIndexes = removeItem.map((item, index) => ({
      ...item,
      position: index,
    }));
    setModifierGroup((prev) => ({
      ...prev,
      addon_items: reassignIndexes,
    }));
  };

  const resetModifierGroup = () => {
    setModifierGroup(defaultModifierGroup);
    setIsEditing(false);
    setErrors({});
  };

  const validateModifierGroup = () => {
    const newErrors = {};
    if (!modifierGroup.addon_cat_name.trim()) {
      newErrors.addon_cat_name = langData.modifier_group_name_required;
    }
    if (modifierGroup.addon_items.length === 0) {
      newErrors.addon_items = langData.please_add_atleast_one_modifier_item;
    }
    if (
      modifierGroup.addon_items.some((item) => !item.addon_item_name.trim())
    ) {
      modifierGroup.addon_items.forEach((item, index) => {
        if (!item.addon_item_name.trim()) {
          newErrors[`addon_item_name_${index}`] =
            langData.modifier_item_name_required;
        }
      });
    }
    if (modifierGroup.addon_items.some((item) => !item.base_price)) {
      modifierGroup.addon_items.forEach((item, index) => {
        if (item.base_price === null || isNaN(item.base_price)) {
          newErrors[`base_price_${index}`] = langData.base_price_required;
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function handleChangeInValues(obj1, obj2) {
    if (obj1 === obj2) return false;
    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 === null ||
      obj2 === null
    ) {
      return obj1 !== obj2;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return true;

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
      if (!keys2.includes(key) || handleChangeInValues(obj1[key], obj2[key])) {
        return true;
      }
    }

    return false;
  }

  return {
    modifierGroup,
    errors,
    isEditing,
    updateModifierGroup,
    updateAddonItem,
    addAddonItem,
    removeAddonItem,
    resetModifierGroup,
    validateModifierGroup,
    initialModifierGroup,
    handleChangeInValues,
  };
};

export default useModifierGroup;

import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import SimpleTable from "../../../../components/SimpleTable";
import styles from "../../../../styles/index";
import {
  fetchAllTaxProfiles,
  fetchTaxProfileAssociationList,
  fetchTaxes,
} from "../../../../redux/actions";
import { AvenirBlackH4 } from "../../../../utils/text";
import {
  CircleCheck,
  CircleClose,
  PluseIcon as CirclePluse,
} from "../../../../components/SVG";
import MyContext from "../../../../Provider/MyContext";
import {
  SnackBar,
  MainBodyWrapper,
  TitleWrapper,
  ImportantReminder,
} from "../../../../components";
import AlertDialog from "../../../../components/Dialog";
import { useDrawers } from "../../../../custom-hooks";
import { replaceWithDynamicText } from "../../../../utils/util";
import EmptyLandingPage from "../../../../components/EmptyLandingPage";
import CreateTaxProfile from "./createTaxProfile";
import EditTaxProfile from "./editTaxProfile";

const TaxProfilesList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const taxProfiles = useSelector(
    (state) => state.taxConfigurationReducer.taxProfiles
  );
  const taxProfileAssociations = useSelector(
    (state) => state.taxConfigurationReducer.taxProfileAssociations
  );
  const navigate = useNavigate();
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [row, setRow] = useState({});
  const dispatch = useDispatch();
  const [taxes, setTaxes] = useState([]);
  const TaxesData = useSelector((state) => state.taxConfigurationReducer.taxes);
  const [snackbar, setSnackbar] = useState(null);

  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const [batchReassignResponse, setBatchReassignResponse] = useState([]);
  const { drawers, toggleDrawer } = useDrawers({
    reAssignDialog: false,
  });

  useEffect(() => {
    dispatch(fetchTaxes(restId.rest_id));
    dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));
    dispatch(fetchTaxProfileAssociationList());
  }, []);

  useEffect(() => {
    setTaxes(processTaxProfiles(taxProfiles, taxProfileAssociations));
  }, [taxProfiles, taxProfileAssociations]);

  function processTaxProfiles(taxProfiles, taxProfileAssociations) {
    return taxProfiles.map((d) => {
      let hasAssigned = false;
      let taxReductionText = Context.langData.tax_profile_not_applied;
      if (d.hasSpecialRule)
        taxReductionText = Context.langData.tax_profile_applied;
      let categoryText = `0 ${Context.langData.categories}, 0 ${Context.langData.items}`;
      if (taxProfileAssociations) {
        const taxProfileAssociation = taxProfileAssociations.find(
          (e) => e.id === d.id
        );
        if (taxProfileAssociation) {
          let itemCount = taxProfileAssociation.items.length;
          taxProfileAssociation.categories.forEach((element) => {
            itemCount += element.items.length;
          });
          categoryText = `${taxProfileAssociation.categories.length} ${
            Context.langData.categories
          }, ${itemCount} ${Context.langData.items}`;
          hasAssigned = !(
            taxProfileAssociation.categories.length === 0 && itemCount === 0
          );
        }
      }
      return {
        ...d,
        taxReductionText,
        categoryText,
        hasAssigned,
      };
    });
  }

  const [columns, setColumns] = useState([
    {
      field: "name",
      display: Context.langData.tax_profile,
      size: "20%",
      sort: false,
    },
    {
      field: "desc",
      display: Context.langData.comment,
      size: "20%",
      sort: false,
    },
    {
      field: "taxes",
      display: "Tax",
      size: "20%",
      sort: false,
      // showSort: false
    },
    {
      field: "taxReductionText",
      display: Context.langData.tax_reduction_column,
      size: "20%",
      sort: false,
    },
    {
      field: "categoryText",
      display: Context.langData.categories,
      size: "20%",
      sort: false,
    },
  ]);
  const onClickAction = (rowValue) => {
    setRow(rowValue);
    toggleEditDrawer(true);
  };
  const btnProps = {};
  btnProps.disabled = true;
  function commonCallback() {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));
  }
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_create_failed,
        severity: "error",
      });
    }
    commonCallback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_edit_failed,
        severity: "error",
      });
    }
    commonCallback();
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_delete_failed,
        severity: "error",
      });
    }
    commonCallback();
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    sortValue = !sortValue;
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortComments = _.orderBy(
      taxes,
      [(d) => d[value].toLowerCase()],
      [sortValue ? "desc" : "asc"]
    );
    setTaxes(processTaxProfiles(sortComments, taxProfileAssociations));
    const sortTaxes = _.orderBy(
      taxes,
      [(d) => d[value].toLowerCase()],
      [sortValue ? "desc" : "asc"]
    );
    setTaxes(processTaxProfiles(sortTaxes, taxProfileAssociations));
  };

  const handleBatchReassignmentCallback = (response) => {
    if (response) {
      dispatch(fetchTaxProfileAssociationList());
      dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));

      const messageArray = Object.keys(response).flatMap((item) => {
        if (response[item].length > 0 && response[item][0].length > 0) {
          if (item === "succeed_categories") {
            return {
              status: "success",
              name: "categories",
              count: response[item][0].length,
            };
          }
          if (item === "succeed_items") {
            return {
              status: "success",
              name: "items",
              count: response[item][0].length,
            };
          }
          if (item === "failed_categories") {
            return {
              status: "failed",
              name: "categories",
              count: response[item][0].length,
            };
          }
          if (item === "failed_items") {
            return {
              status: "failed",
              name: "items",
              count: response[item][0].length,
            };
          }
        }
        return [];
      });

      setBatchReassignResponse(messageArray);
      toggleDrawer("reAssignDialog", true);
    }
  };
  const handleClick = () => {
    navigate("/home/settings/taxes");
  };
  const renderSummary = () => (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
      }}>
      {batchReassignResponse?.map(
        (item, index) =>
          item && (
            <Grid
              key={index}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <Grid style={{ marginRight: "3px" }}>
                {item.status === "success" ? (
                  <CircleCheck width={16} height={16} color='#007191' />
                ) : (
                  <CircleClose width={16} height={16} color='#AB1212' />
                )}
              </Grid>
              {item.count === 1 && item.name === "categories" && (
                <AvenirBlackH4
                  label={replaceWithDynamicText(
                    item.status === "success"
                      ? Context.langData.moved_category
                      : Context.langData.failed_moved_category,
                    item
                  )}
                />
              )}
              {item.count > 1 && item.name === "categories" && (
                <AvenirBlackH4
                  label={replaceWithDynamicText(
                    item.status === "success"
                      ? Context.langData.moved_categories
                      : Context.langData.failed_moved_categories,
                    item
                  )}
                />
              )}
              {item.count === 1 && item.name === "items" && (
                <AvenirBlackH4
                  label={replaceWithDynamicText(
                    item.status === "success"
                      ? Context.langData.moved_item
                      : Context.langData.failed_moved_item,
                    item
                  )}
                />
              )}
              {item.count > 1 && item.name === "items" && (
                <AvenirBlackH4
                  label={replaceWithDynamicText(
                    item.status === "success"
                      ? Context.langData.moved_items
                      : Context.langData.failed_moved_items,
                    item
                  )}
                />
              )}
            </Grid>
          )
      )}
    </Grid>
  );

  const renderLandingPage = () => {
    if (taxes.length > 0) {
      return (
        <SimpleTable
          data-qaid='taxProfiles_simpleTable'
          columns={columns}
          rows={taxes}
          isEditArrow
          isOnClick
          sortingOnClick={sortingOnClick}
          onRowClick={onClickAction}
        />
      );
    }
    if (TaxesData.length > 0) {
      return (
        <Grid style={{ margin: "10px 0px" }}>
          <EmptyLandingPage
            type='Button'
            title={Context.langData.no_tax_profiles_added}
            bodyTextPrimary={Context.langData.all_local_sales_tax_profile}
            buttonText={Context.langData.create_taxes_profile}
            onButtonClick={() => toggleCreateDrawer(true)}
          />
        </Grid>
      );
    }

    return (
      <Grid style={{ margin: "10px 0px" }}>
        <EmptyLandingPage
          type='Route'
          title={Context.langData.no_tax_profiles_added}
          bodyTextPrimary={Context.langData.all_local_sales_tax_profile}
          boldText={Context.langData.taxes}
          bodyTextSecondary={Context.langData.taxes_need_to_be_set_up_first}
          routeText='Setting/Payments/Taxes'
          onRouteClick={() => handleClick()}
        />
      </Grid>
    );
  };

  return (
    <MainBodyWrapper>
      <div data-qaid='taxeslist_div'>
        <TitleWrapper
          mainTitle={Context.langData.settings}
          subTitle={Context.langData.taxProfiles}>
          <Grid
            data-qaid='taxeslist_grid'
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}>
            <Button
              className={style.buttonClass}
              data-qaid='taxeslist_creategrid'
              style={{
                backgroundColor: TaxesData.length > 0 ? "#FFB600" : "#C3C3C3",
                color: "white",
              }}
              variant='contained'
              id='cancel'
              disabled={TaxesData.length === 0}
              onClick={() => toggleCreateDrawer(true)}>
              <CirclePluse data-qaid='taxeslist_createpluse' />
              <AvenirBlackH4
                data-qaid='taxeslist_createlabel'
                label={Context.langData.create_taxes_profile}
                style={{
                  paddingLeft: 5,
                  color: taxes.length === 0 ? "#707272" : "black",
                }}
              />
            </Button>
          </Grid>
        </TitleWrapper>
        <ImportantReminder message={Context.langData.tax_important_reminder} />

        {renderLandingPage()}
        {openCreate && (
          <CreateTaxProfile
            data-qaid='taxeslist_createtaxes'
            open={openCreate}
            taxes={taxes}
            onClose={() => toggleCreateDrawer(false)}
            callback={createCallback}
          />
        )}
        {openEdit && (
          <EditTaxProfile
            data-qaid='taxeslist_edittaxes'
            open={openEdit}
            row={row}
            taxes={taxes}
            onClose={() => toggleEditDrawer(false)}
            editcallback={editCallback}
            deleteCallback={deleteCallback}
            reassignmentCallBack={handleBatchReassignmentCallback}
          />
        )}
        {snackbar && (
          <SnackBar
            data-qaid='taxeslist_snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
        <AlertDialog
          open={drawers.reAssignDialog}
          onClose={() => {
            toggleDrawer("reAssignDialog", false);
          }}
          title={Context.langData.reassignment_complete}
          primaryButtonText={Context.langData.done}
          onPrimaryAction={() => toggleDrawer("reAssignDialog", false)}
          // eslint-disable-next-line react/no-children-prop
          children={renderSummary()}
          primaryButtonType='single'
        />
      </div>
    </MainBodyWrapper>
  );
};

export default TaxProfilesList;
